import firebase from '../firebase';
import { toArray } from '../../util';

const firestore = firebase.firestore();

export async function getAllCategories() {
    try {
        let all = await firestore.collection('categories').get();
        all = toArray(all);

        const mainCategories = all
            .filter((cat) => cat.parent_id === 'unset')
            .reduce(
                (result, cat) => ({
                    ...result,
                    [cat.id]: {
                        ...cat,
                        subCategories: [],
                    },
                }),
                {},
            );

        all.filter((cat) => cat.parent_id !== 'unset').forEach((cat) => {
            if (!(cat.parent_id in mainCategories)) {
                return;
            }

            mainCategories[cat.parent_id].subCategories.push(cat);
        });
        return Object.values(mainCategories);
    } catch (e) {
        console.log(e);
        return [];
    }
}

export function getCategoryTitleById(categories, id) {
    const mainFound = categories.find((e) => e.id === id);
    if (mainFound) {
        return mainFound.title;
    }

    const subCategories = categories
        .map((c) => c.subCategories)
        // flat
        .reduce((result, array) => [...result, ...array], []);
    const subFound = subCategories.find((e) => e.id === id);
    if (subFound) {
        return subFound.title;
    }

    return 'Ismeretlen kategória';
}

export async function createCategory(category) {
    if (!category.title.trim()) {
        throw new Error('category title must not be empty');
    }

    if (category.title.trim().length > 25) {
        throw new Error('category title too long');
    }

    if (!category.parent_id && !category.icon.trim()) {
        throw new Error('main category icon required');
    }

    const validatedCategory = {
        parent_id: category.parent_id || 'unset',
        title: category.title.trim(),
    };
    if (category.parent_id === null) {
        validatedCategory.icon = category.icon.trim();
    }

    await firestore.collection('categories').add(validatedCategory);
}

export async function deleteSubCategory(category) {
    const ref = await firestore
        .collection('articles')
        .where('category', '==', category.id)
        .limit(1)
        .get();
    const atLeastOneArticle = toArray(ref);
    if (atLeastOneArticle.length > 0) {
        throw new Error('van hozzárendelt hirdetés');
    }

    await firestore.collection('categories').doc(category.id).delete();
}
